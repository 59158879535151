import { React, useState, useEffect } from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
// import Hoverimg from "../assets/images/smart_hover_state_img.png";
import { businessCatalogueApi } from "../API/homeAPI";
import { motion } from "framer-motion";
// import Fade from 'react-reveal/Fade';

const BusinessCatalogue = () => {
  const [valueTitle, setValueTitle] = useState([]);
  const [valueContent, setValueContent] = useState([]);
  const [businessSolution, setBusinessSolution] = useState([]);

  const [businessDesc, setBusinessDesc] = useState([]);
  const [businessData, setbusinessData] = useState([]);
  // const [businessDesList, setbusinessDesList] =useState ([{
  //   businessTitle: "",
  //   businessSubTitle: "",
  //   businessDescriptionValue: [],

  // }]);
  const businessList = [];
  const catalogueDes = [];
  const businessAnimate = {
    offscreen: { x: -100, opacity: 0 },
    onscreen: {
      x: 0,
      opacity: 1,
      //rotate:[0,360,-360,360,0],
      transition: {
        //type:"spring",
        ease: "easeInOut",
        bounce: 0.4,
        duration: 3,
      },
    },
  };
  const businessTextAnimate = {
    offscreen: { y: 100, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        ease: "easeInOut",
        bounce: 0.4,
        duration: 3,
      },
    },
  };

  const businessBoxAnimate = {
    offscreen: { y: 75, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        ease: "easeInOut",
        bounce: 0.4,
        duration: 3,
      },
    },
  };

  useEffect(() => {
    var config = { "Access-Control-Allow-Origin": "*" };
    businessCatalogueApi(
      config,
      (response) => {
        //success
        const earnTitle = response.data.map((item) => item.contentTitle);
        const earnContent = response.data.map(
          (item) => item.contentDescription
        );

        //console.log("business catalog", response.data);

        const catSolution = response.data.map((item) => item.contentElements);
        const catalogueSol = catSolution[0];

        setValueTitle(earnTitle);
        setValueContent(earnContent);
        setBusinessSolution(catalogueSol);

        const businessElement = [];

        for (let i = 0; i < catalogueSol.length; i++) {
          const Title = catalogueSol[i].ElementTitle;
          const SubTitle = catalogueSol[i].ElementSubTitle;
          const businessDescription = [];
          const Logo = catalogueSol[i].ElementLogo;

          for (let v = 0; v < catalogueSol[i].ElementDescription.length; v++) {
            businessDescription.push(
              catalogueSol[i].ElementDescription[v].ElementDescriptionValue
            );
            // console.log("businessElement[v].", catalogueSol[i].ElementDescription[v].ElementDescriptionValue);
          }

          businessData.push({
            businessTitle: Title,
            businessSubTitle: SubTitle,
            businessDescriptionValue: businessDescription,
            businessLogo: Logo,
          });

          //businessElement.push(catalogueSol[i].ElementDescription);
          // console.log("metricBox[i].", catalogueSol[i].ElementDescription);
        }
        // setbusinessData(businessList)
        //console.log("businessList",businessList);
        // console.log("businessData",businessData);
        // console.log("businessElement[i].", businessElement[0]);

        setBusinessDesc(catalogueDes);
        //console.log("businessElement", businessElement);
        // setBusinessSolution(response.data[0].contentElements);
        // console.log("businessCatalogueApi", response.data);
        //console.log("catSolution", catSolution[0]);
      },
      (err) => {
        //error
        console.log(err);
      }
    );
  }, []);

  //console.log("businessDesc", businessDesc);

  return (
    <div className="bics-catalogue-wrapper py-md-5 py-3 py-lg-5 py-xl-6 py-sm-3">
      <Container>
        <div className="bics-header-wrapper">
          <div className="bics-inner-wrapper pb-md-4 pb-lg-4 pb-sm-2 pb-2 pb-xl-4">
            <h1 className="bics-header">{valueTitle}</h1>

            <p className="bics-content">{valueContent}</p>
          </div>
        </div>

        <Row className="d-flex justify-content-center">
          {businessData.map((item, index) => (
            <Col sm={12} md={6} lg={4} key={index}>
              <Card className="bics-cardbox text-left mb-4">
                <Card.Body>
                  <motion.div
                    className="bics-flip-front"
                    initial={"offscreen"}
                    whileInView={"onscreen"}
                    viewport={{ once: false, amount: 0.1 }}
                    transition={{ staggerChildren: 0.5 }}
                  >
                    <motion.div
                      className="bics-animate"
                      variants={businessTextAnimate}
                    >
                      <Card.Title>
                        <span>{item.businessTitle}</span>{" "}
                        {item.businessSubTitle}
                      </Card.Title>
                    </motion.div>
                    <motion.div
                      className="bics-animate"
                      variants={businessTextAnimate}
                    >
                      <ListGroup className="border-none">
                        {item.businessDescriptionValue.map((desc, dindex) => (
                          <ListGroup.Item key={dindex}>{desc}</ListGroup.Item>
                        ))}
                        {/* <ListGroup.Item>Marketing Cloud</ListGroup.Item> */}
                      </ListGroup>
                    </motion.div>
                  </motion.div>
                </Card.Body>
                <div className="bics-flip-box">
                  <img
                    className="d-block w-100"
                    src={process.env.REACT_APP_BASE_URL + item.businessLogo}
                    alt="First slide"
                  />
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default BusinessCatalogue;
