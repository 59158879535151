module.exports={
  sub1:[{
      lable:"DIGITAL CMS",
      name:"DigitalCMS",
      route:"/digitalcms",

      Items:[
      {
        lable:"WordPress",
        name:"wordpress",
        route:"/digitalcms",
      },
      {
        lable:"Drupal",
        name:"drupal",
        route:"/digitalcms",
      },
      {
        lable:"Contentfull",
        name:"contentfull",
        route:"/digitalcms",
      },
      {
        lable:"Contentstack",
        name:"contentstack",
        route:"/digitalcms",
      },
      {
        lable:"SEO",
        name:"seo",
        route:"/digitalcms",
      },
      {
        lable:"Crownpeak",
        name:"crownpeak",
        route:"/digitalcms",
      },
      {
        lable:"Strapi",
        name:"strapi",
        route:"/digitalcms",
      },
   
      ]
    },
    {
      lable:"DIGITAL COMMERCE",
      name:"DigitalCommerce",
      route:"/ecommerce",
      Items:[
        {
          lable:"Magento",
          name:"Magento",
          route:"/ecommerce"
        },
        {
          lable:"Word press",
          name:"wordpress",
          route:"/ecommerce",
        },
        {
          lable:"Prestashop",
          name:"Prestashop",
          route:"/ecommerce",
        },
        {
          lable:"Commerce Tools",
          name:"commercetool",
          route:"/ecommerce",
        },
        {
          lable:"Shopify",
          name:"Shopify",
          route:"/ecommerce",
        },
        {
          lable:"VTEX",
          name:"VTEX",
          route:"/ecommerce",
        },
      ]
    },
    {
      lable:"DIGITAL MOBILITY",
      name:"digitalmobility",
      route:"/mobility",
      Items:[
        {
          lable:"Native iOS",
          name:"ios",
          route:"/mobility",
        },
        {
          lable:"Native Android",
          name:"android",
          route:"/mobility",
        },
        {
          lable:"React Native",
          name:"react",
          route:"/mobility",
        },
        {
          lable:"Flutter",
          name:"flutter",
          route:"/mobility",
        },
      ]
    },
  
    {
      lable:"TECHNOLOGY",
      name:"Technology",
      route:"/technologies",
      Items:[
        {
          lable:"Python",
          name:"python",
          route:"/technologies",
        },
        {
          lable:"Dotnet",
          name:"dotnet",
          route:"/technologies",
        },
        {
          lable:"php",
          name:"php",
          route:"/technologies",
        },
        {
          lable:"MERN/MEAN",
          name:"mern",
          route:"/technologies",
        },
        {
          lable:"AR/VR",
          name:"arvr",
          route:"/technologies",
        },
       
      ]
    },
    {
      lable:"UI / UX",
      name:"uxui",
      route:"/uiux",
      Items:[
        {
          lable:"Figma",
          name:"figma",
          route:"/uiux",
        },
        {
          lable:"Adboe XD",
          name:"adobexd",
          route:"/uiux",
        },
        {
          lable:"Adobe PhotoShop",
          name:"photoshop",
          route:"/uiux",
        },
        {
          lable:"Adobe Illustrator",
          name:"adobeillustrator",
          route:"/uiux",
        },
        {
          lable:"HTML",
          name:"html",
          route:"/uiux",
        },
        {
          lable:"CSS",
          name:"css",
          route:"/uiux",
        },
      ]
    },
    {
      lable:"LMS",
      name:"LMS",
      route:"/lms",
      Items:[
        {
          lable:"Absorb",
          name:"Absorb",
          route:"/lms",
        },
        {
          lable:"Moodle",
          name:"Moodle",
          route:"/lms",

        },
        {
          lable:"Compliance Wire",
          name:"Complinewire",
          route:"/lms",

        },
      ]
    },
    ],
  sub2:[
    {
      lable:"ARTIFICIAL INTELLIGENCE",
      name:"ArtificialIntelligence",
      route:'/intelligent',
      Items:[
        {
          lable:"NLP",
          name:"NLP", 
          route:'/intelligent',
        },
        {
          lable:"LLM",
          name:"LLM", 
          route:'/intelligent',
        },
        {
          lable:"RAG",
          name:"RAG", 
          route:'/intelligent',
        },
        {
          lable:"Open Ai",
          name:"openai", 
          route:'/intelligent',
        },
        {
          lable:"MS Copilot",
          name:"Copilot", 
          route:'/intelligent',
        },
        {
          lable:"Generative Ai",
          name:"Generative", 
          route:'/intelligent',
        }
      ]
    },
      {
        lable:"ANALYTICS",
        name:"Analytics",
        route:"/analytics",
        Items:[
          {
            lable:"Tableau",
            name:"tableau",
          route:"/analytics"

          },
          {
            lable:"Power Bi",
            name:"powerbi",
            route:"/analytics",

          }
        ]

      },
      {
        lable:"AUTOMATION",
        name:"Automation",
        route:"/automations",
        Items:[
          {
            lable:"RPA",
            name:"rpa",
            route:"/automations",

          },
          {
            lable:"Ui Path",
            name:"uipath",
            route:"/automations",

          },
          {
            lable:"Selenium",
            name:"Selenium",
            route:"/automations",

          }
        ]
      },
      {
        lable:"DEVOPS",
        name:"devops",
        route:"/devops",

        Items:[
          {
            lable:"gog 's",
            name:"gog",
            route:"/devops",
          },
          {
            lable:"Docker",
            name:"docker",
            route:"/devops",

          },
          {
            lable:"Jenkins",
            name:"Jenkins",
            route:"/devops",

          },
          {
            lable:"Grafana",
            name:"Grafana",
            route:"/devops",

          },
          {
            lable:"Terraform",
            name:"Terraform",
            route:"/devops",

          },
          {
            lable:"SonarQube",
            name:"SonarQube",
            route:"/devops",

          },
          {
            lable:"Kubernetes",
            name:"Kubernetes",
            route:"/devops",

          },
          {
            lable:"Prometheus",
            name:"Prometheus",
            route:"/devops",
            
          },
        ]
      },
      {
        lable:"CLOUD",
        name:"cloud",
        route:"/cloud",
      },
    
    ],
    sub3:[
      {
        lable:"ARTIFICIAL INTELLIGENCE",
        name:"ArtificialIntelligence",
        route:'/intelligent',
        Items:[
          {
            lable:"NLP",
            name:"NLP", 
            route:'/intelligent',
          },
          {
            lable:"LLM",
            name:"LLM", 
            route:'/intelligent',
          },
          {
            lable:"RAG",
            name:"RAG", 
            route:'/intelligent',
          },
          {
            lable:"Open Ai",
            name:"openai", 
            route:'/intelligent',
          },
          {
            lable:"MS Copilot",
            name:"Copilot", 
            route:'/intelligent',
          },
          {
            lable:"Generative Ai",
            name:"Generative", 
            route:'/intelligent',
          }
        ]
      },
   
      
    ],
    sub4:[
      {
        lable:"SALES FORCE",
        name:"salseforce",
        route:"/salesforce",
        
        Items:[
          {
            lable:"Sales Colud",
            name:"salescloud",
            route:"/salescloud",
          },
          {
            lable:"Service cloud",
            name:"servicecloud",
            route:"/servicecloud",
          },
          {
            lable:"Health cloud",
            name:"healthcloud",
            route:"/healthcloud",
          },
          {
            lable:"Commerce cloud",
            name:"commercecloud",
            route:"/ecommercecloud",
          },
          {
            lable:"Einstein",
            name:"Einstein",
            route:"/einstein",
          }
      
          ]
        
      },
      {
        lable:"MARTKETING CLOUD",
        name:"marketingcloud",
        route:"/marketingcloud",
        Items:[
          {
            lable:"SFMC",
            name:"marketingcloud",
            route:"/marketingcloud"
          },
          {
            lable:"Datorama",
            name:"Datorama",
            route:"/marketingcloud"
          },
          {
            lable:"CDP",
            name:"CDP",
            route:"/marketingcloud"
          },
          {
            lable:"Data Cloud",
            name:"DataCloud",
            route:"/marketingcloud"
          }
        ]
      },
      {
        lable:"SAP",
        name:"sap",
        route:"/sap",
        Items:[
          {
            lable:"s4 Hana",
            name:"s4hana",
            route:"/sap"
            
          },
          {
            lable:"SAP ECC",
            name:"sapecc",
            route:"/sap"
          },
          {
            lable:"BODS",
            name:"SAPBODS",
            route:"/sap"
          },
          {
            lable:"PP",
            name:"pp",
            route:"/sap"
          },
          {
            lable:"PS",
            name:"ps",
            route:"/sap"
          },
          {
            lable:"PM",
            name:"pm",
            route:"/sap"
          },
        ]
      },

    ],
    
}