import React from "react";
// import { useEffect, useState, useRef } from "react";
import VideoPlayer from "react-video-js-player";
import Posterimg from "../assets/images/hero_banner.png";

import config from "../config.json"

var baseurl = `${config.baseurl}`;

const VideoOfCustomer = ({ videoData }) => {

    const poster = Posterimg;
    var videoSource = "https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.mp4";

    if (videoData !== undefined) {
           if (videoData.ElementTitle === "Video") {
            videoSource = baseurl + videoData.ElementUrl;
           }
    }
    //console.log("videosource", videoSource)
    return(
        <div className="bics-video-container">
              <VideoPlayer
        controls={true}
        src={videoSource}
        width="100%"
        height="385"
        poster={poster}
      />
        </div>
    )
}

export default VideoOfCustomer