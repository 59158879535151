import React from "react";
import { useState, useEffect, useRef } from "react";
// import { NavLink } from 'react-router-dom';
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import Logo from "./logo";
// import { Link } from "react-router-dom";
// import { Scrollbars } from 'react-custom-scrollbars';
import { Animated } from "react-animated-css";
import { useParams } from "react-router-dom";
// import nav_cloud from "../assets/images/icons/nav_cloud.png";
// import nav_digital from "../assets/images/icons/nav_digital.png";
// import nav_salesforce from "../assets/images/icons/nav_salesforce.png";
// import nav_smart from "../assets/images/icons/nav_smart.png";
// import nav_technology from "../assets/images/icons/nav_technology.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { LinkContainer } from "react-router-bootstrap";
// import $ from "jquery";
import jQuery from "jquery";
import { aboutMenuAPI, menuAPI, futuristicMenuAPI } from "../API/menuAPI";

const AppHeader = () => {
  
  const [params,setParams]=useState(useParams())
  console.log(params)
  const [show, setShow] = useState(false);
  const [mainMenu, setMainmenu] = useState([]);

  const [submenuList, setsubmenuList] = useState([]);

  const [subMenu, setSubmenu] = useState([]);
  const [menudesc, setMenudesc] = useState([]);
  const [menuImage, setmenuImage] = useState([]);
  const [menuLink, setmenuLink] = useState([]);

  const [aboutMenu, setAboutmenu] = useState([]);
  const [aboutsubmenuList, setAboutsubmenuList] = useState([]);
  const [aboutsubMenu, setaboutSubmenu] = useState([]);
  const [aboutmenudesc, setaboutMenudesc] = useState([]);
  const [aboutmenuImage, setaboutmenuImage] = useState([]);
  const [aboutmenuLink, setaboutmenuLink] = useState([]);

  // const [futuristicMenu, setFuturisticmenu] = useState([]);
  // const [futuristicsubmenuList, setFuturisticsubmenuList] = useState([]);
  // const [futuristicsubMenu, setfuturisticSubmenu] = useState([]);
  // const [futuristicmenudesc, setfuturisticMenudesc] = useState([]);
  // const [futuristicmenuImage, setfuturisticmenuImage] = useState([]);
  // const [futuristicmenuLink, setfuturisticmenuLink] = useState([]);

  const sub_title = [];
  const sub_Description = [];
  const sub_image = [];
  const sub_Links = [];

  const aboutsub_title = [];
  const aboutsub_Description = [];
  const aboutsub_image = [];
  const aboutsub_Links = [];

  // const futuristicsub_title = [];
  // const futuristicsub_Description = [];
  // const futuristicsub_image = [];
  // const futuristicsub_Links = [];
  // const showDropdown = (e) => {
  //   setShow(!show);
  // };
  // const hideDropdown = (e) => {
  //   setShow(false);
  // };
  (function ($) {
    // $(window).on("load",function(){
    //     $(".content").mCustomScrollbar();
    // });
  })(jQuery);

  useEffect(() => {
    // $('#nav-icon1').on('click', function(e) {
    //   // $('body').toggleClass("collapsed pressed"); //you can list several class names
    //   e.preventDefault();
    // });

    // $('#nav-icon1').click(function(){
    //   $(this).toggleClass('open');
    // });

    //   $(document).ready(function () {
    //     $('.dropdown-menu a').on('click', function () {
    //       $('.navbar-toggler').hide()
    //     });

    // });

    var config = { "Access-Control-Allow-Origin": "*" };
    menuAPI(
      config,
      (response) => {
        //success
        //  console.log("banner data", response.data[0]);
        const mainHead = response.data[0].contentTitle;
        const subHead = response.data[0].contentElements;
        setMainmenu(mainHead);
        setsubmenuList(subHead);
        // console.log("subMenu_list",subHead);
        for (let i = 0; i < subHead.length; i++) {
          sub_title.push(subHead[i].ElementTitle);
          sub_Description.push(subHead[i].ElementDescription);
          sub_image.push(subHead[i].ElementImage);
          sub_Links.push(subHead[i].ElementUrl);
        }
        setSubmenu(sub_title);
        setMenudesc(sub_Description);
        setmenuLink(sub_Links);
        setmenuImage(sub_image);
      },
      (err) => {
        //error
        console.log(err);
      }
    );
    aboutMenuAPI(
      config,
      (response) => {
        //success
        //console.log("menu about data", response.data[0]);
        const aboutHead = response.data[0].contentTitle;
        const aboutsubHead = response.data[0].contentElements;
        setAboutmenu(aboutHead);
        setAboutsubmenuList(aboutsubHead);
        // console.log("subMenu_list",aboutsubHead);
        for (let i = 0; i < aboutsubHead.length; i++) {
          aboutsub_title.push(aboutsubHead[i].ElementTitle);
          aboutsub_Description.push(aboutsubHead[i].ElementDescription);
          aboutsub_image.push(aboutsubHead[i].ElementImage);
          aboutsub_Links.push(aboutsubHead[i].ElementUrl);
        }
        setaboutSubmenu(aboutsub_title);
        setaboutMenudesc(aboutsub_Description);
        setaboutmenuLink(aboutsub_Links);
        setaboutmenuImage(aboutsub_image);
      },
      (err) => {
        //error
        console.log(err);
      }
    );
  }, []);

  
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="dark"
      className="bics-navbar"
    >
      <Container>
        {/* <Link to="/" className="logo_link">
          <Logo></Logo>
        </Link> */}
        <LinkContainer className="nav-link" to="/">
          <Nav.Link>
            <Logo></Logo>
          </Nav.Link>
        </LinkContainer>
        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav">
          <div id="nav-icon1">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav style={{ maxHeight: "100%" }}>
            <ul className="list-group-horizontal mobile_menu_block_scroll mCustomScrollbar">
              {/* <li>
                <NavDropdown
                  drop="down"
                  title={
                    <span>
                      <span className="upcoming-header">{futuristicMenu}</span>
                      &nbsp;&nbsp;
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  }
                  id="basic-nav-dropdown"
                  className="bics-nav-dropdown main_menu"
                >
                  <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    animationInDuration={1000}
                    animationInDelay={100}
                    isVisible={true}
                  >
                    {futuristicsubmenuList.map((item, index) => (
                      <LinkContainer
                        className="nav-link"
                        key={index}
                        to={futuristicmenuLink[index]}
                      >
                        <Nav.Link className="sub-link-box">
                          <div className="media">
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                futuristicmenuImage[index]
                              }
                              alt="icon"
                              className="submenu-icon"
                            />
                            <div className="media-body">
                              <h5>{futuristicsubMenu[index]}</h5>
                              <p className="normalWhiteSpace">
                                {futuristicmenudesc[index]}
                              </p>
                            </div>
                          </div>
                        </Nav.Link>
                      </LinkContainer>
                    ))}
                  </Animated>
              
                </NavDropdown>
              </li> */}
              <li>
                {/* <Link className="nav-link" to="/salesforce">                      
                      Salesforce
                    </Link> */}
                <NavDropdown
                  //  show={show}
                  //  onMouseEnter={showDropdown}
                  //  onMouseLeave={hideDropdown}

                  drop="down"
                  // title="Solutions"
                  title={
                    <span>
                      {mainMenu}&nbsp;&nbsp;
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  }
                  id="basic-nav-dropdown"
                  className="bics-nav-dropdown main_menu"
                >
                  {/* <Scrollbars
                className='desktop_scroll_fix mb-2'
   // autoHide
    autoHideTimeout={1000}
    autoHideDuration={200}
    autoHeight
    autoHeightMin={0}
    autoHeightMax={320}
    thumbMinSize={30}> */}
                  <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    animationInDuration={1000}
                    animationInDelay={100}
                    isVisible={true}
                  >
                    {submenuList.map((item, index) => (
                      <LinkContainer
                        className="nav-link"
                        key={index}
                        to={menuLink[index]}
                      >
                        <Nav.Link className="sub-link-box">
                          <div className="media">
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                menuImage[index]
                              }
                              alt="icon"
                              className="submenu-icon"
                            />
                            <div className="media-body">
                              <h5>{subMenu[index]}</h5>
                              <p className="normalWhiteSpace">
                                {menudesc[index]}
                              </p>
                            </div>
                          </div>
                        </Nav.Link>
                      </LinkContainer>
                    ))}
                    {/* <LinkContainer className="nav-link" to="/commerce">
                      <NavDropdown.Item>
                        <div className="media">
                          <img
                            src={nav_salesforce}
                            alt="icon"
                            className="submenu-icon"
                          />
                          <div className="media-body">
                            <h5>Commerce Solutions</h5>
                            lorum ipsum lorum ipsum lorum ipsum
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/marketingcloud">
                      <NavDropdown.Item>
                        <div className="media">
                          <img
                            src={nav_salesforce}
                            alt="icon"
                            className="submenu-icon"
                          />
                          <div className="media-body">
                            <h5> Marketing Cloud</h5>
                            lorum ipsum lorum ipsum lorum ipsum
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/smart">
                      <NavDropdown.Item>
                        <div className="media">
                          <img
                            src={nav_smart}
                            alt="icon"
                            className="submenu-icon"
                          />
                          <div className="media-body">
                            <h5> Smart Solutions</h5>
                            lorum ipsum lorum ipsum lorum ipsum
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/technology">
                      <NavDropdown.Item>
                        <div className="media">
                          <img
                            src={nav_technology}
                            alt="icon"
                            className="submenu-icon"
                          />
                          <div className="media-body">
                            <h5> Technology Solutions</h5>
                            lorum ipsum lorum ipsum lorum ipsum
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/digital">
                      <NavDropdown.Item>
                        <div className="media">
                          <img
                            src={nav_digital}
                            alt="icon"
                            className="submenu-icon"
                          />
                          <div className="media-body">
                            <h5> Digital Solutions</h5>
                            lorum ipsum lorum ipsum lorum ipsum
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/cloud">
                      <NavDropdown.Item>
                        <div className="media">
                          <img
                            src={nav_cloud}
                            alt="icon"
                            className="submenu-icon"
                          />
                          <div className="media-body">
                            <h5> Cloud Solutions</h5>
                            lorum ipsum lorum ipsum lorum ipsum
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer> */}
                    {/* <div className="bics-nav-drop-footer d-flex justify-content-between">
                           <div className="bics-dropdown-footer-inner">
                                <h5>Corporate Profile</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                           </div>
                           <Link className="nav-link btn-view" to="/technology" variant="primary">View Profile </Link>
                    </div> */}
                  </Animated>
                  {/* </Scrollbars> */}
                </NavDropdown>
              </li>
              <li>
                <LinkContainer className="nav-link main_menu" to="/industry">
                  <Nav.Link>Industry</Nav.Link>
                </LinkContainer>
              </li>
              {/* <li>
              <NavDropdown
                  //  show={show}
                  //  onMouseEnter={showDropdown}
                  //  onMouseLeave={hideDropdown}
            
                  drop="down"
                  // title="Solutions"
                  title={
                    <span>
                      Commerce&nbsp;&nbsp;
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  }
                  id="basic-nav-dropdown-1"
                  className="bics-nav-dropdown"
                >
                  <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    animationInDuration={1000}
                    animationInDelay={100}
                    isVisible={true}

                  >
                    <LinkContainer className="nav-link" to="/salesforce" >
                    <NavDropdown.Item>
                      <div className="media">
                        <img
                          src={nav_salesforce}
                          alt="icon"
                          className="submenu-icon"
                        />
                        <div className="media-body">
                          <h5>Salesforce Commerce Cloud</h5>
                          lorum ipsum lorum ipsum lorum ipsum
                        </div>
                      </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/employee">
                    <NavDropdown.Item>
                      <div className="media">
                        <img
                          src={nav_salesforce}
                          alt="icon"
                          className="submenu-icon"
                        />
                        <div className="media-body">
                          <h5>Commerce Tools</h5>
                          lorum ipsum lorum ipsum lorum ipsum
                        </div>
                      </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/benefits">
                    <NavDropdown.Item>
                      <div className="media">
                        <img
                          src={nav_smart}
                          alt="icon"
                          className="submenu-icon"
                        />
                        <div className="media-body">
                          <h5>Prestashop</h5>
                          lorum ipsum lorum ipsum lorum ipsum
                        </div>
                      </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/events">
                    <NavDropdown.Item>
                      <div className="media">
                        <img
                          src={nav_technology}
                          alt="icon"
                          className="submenu-icon"
                        />
                        <div className="media-body">
                          <h5>Magento</h5>
                          lorum ipsum lorum ipsum lorum ipsum
                        </div>
                      </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                  
                  </Animated>
                </NavDropdown>
              </li> */}
              <li>
                {/* <Link className="nav-link" to="/careers">
                  Careers
                </Link> */}
                <LinkContainer className="nav-link main_menu" to="/careers">
                  <Nav.Link>Careers</Nav.Link>
                </LinkContainer>
              </li>
              <li>
                <a
                  className="nav-link main_menu"
                  href="http://bitsglobal.in/"
                  target="_blank"
                >
                  <span className="upcoming-header">Corporate Training</span>
                </a>
              </li>
              <li>
                <NavDropdown
                  //  show={show}
                  //  onMouseEnter={showDropdown}
                  //  onMouseLeave={hideDropdown}

                  drop="down"
                  // title="Solutions"
                  title={
                    <span>
                      About Us&nbsp;&nbsp;
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  }
                  id="basic-nav-dropdown-2"
                  className="bics-nav-dropdown main_menu"
                >
                  <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    animationInDuration={1000}
                    animationInDelay={100}
                    isVisible={true}
                  >
                    {aboutsubmenuList.map((item, index) => (
                      <LinkContainer
                        className="nav-link"
                        key={index}
                        to={aboutmenuLink[index]}
                      >
                        <NavDropdown.Item>
                          <div className="media">
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                aboutmenuImage[index]
                              }
                              alt="icon"
                              className="submenu-icon"
                            />
                            <div className="media-body">
                              <h5>{aboutsubMenu[index]}</h5>
                              <p className="normalWhiteSpace">
                                {aboutmenudesc[index]}
                              </p>
                            </div>
                          </div>
                        </NavDropdown.Item>
                      </LinkContainer>
                    ))}
                    {/* <LinkContainer className="nav-link" to="/employee">
                      <NavDropdown.Item>
                        <div className="media">
                          <img
                            src={nav_salesforce}
                            alt="icon"
                            className="submenu-icon"
                          />
                          <div className="media-body">
                            <h5>Employees and Customers</h5>
                            lorum ipsum lorum ipsum lorum ipsum
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/benefits">
                      <NavDropdown.Item>
                        <div className="media">
                          <img
                            src={nav_smart}
                            alt="icon"
                            className="submenu-icon"
                          />
                          <div className="media-body">
                            <h5> Benefits</h5>
                            lorum ipsum lorum ipsum lorum ipsum
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer className="nav-link" to="/events">
                      <NavDropdown.Item>
                        <div className="media">
                          <img
                            src={nav_technology}
                            alt="icon"
                            className="submenu-icon"
                          />
                          <div className="media-body">
                            <h5> Events</h5>
                            lorum ipsum lorum ipsum lorum ipsum
                          </div>
                        </div>
                      </NavDropdown.Item>
                    </LinkContainer> */}
                  </Animated>
                </NavDropdown>
              </li>
              <li>
                {/* <Link className="nav-link" to="/contactus">
                  Contact Us
                </Link> */}
                <LinkContainer className="nav-link main_menu" to="/contactus">
                  <Nav.Link>Contact Us</Nav.Link>
                </LinkContainer>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppHeader;
