import { React, useEffect, useState } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
// import slideImg from "../assets/images/food_delivery_pop.png";
import { productCatalogueApi } from '../API/homeAPI';
import {motion, AnimatePresence, AnimateSharedLayout,} from "framer-motion";

const responsive = {
    // superLargeDesktop: {
    //   // the naming can be any, depends on you.
    //   breakpoint: { max: 4000, min: 3000 },
    //   items: 5
    // },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 1
    }
  };
  const productAnimate={
    offscreen:{rotateY:180, opacity:0,},
    onscreen:{
      rotateY:0,
      opacity:1,
      //rotate:[0,360,-360,360,0],
      transition:{
      //type:"spring",
      ease: "easeInOut",
      bounce:0.3,
      duration:1,
     // delay:0.1,
      delayChildren: 0.1,
      }
    }
  }

  const productTextAnimate={
    offscreen:{y:35, opacity:0},
    onscreen:{
      y:0,
      opacity:1,
      transition:{
      type:"spring",
      ease: "easeInOut",
      bounce:0.4,
      duration:1,
      
      }
    }
  }

const ProductCatalogue = ({children}) => {
  const [productCatData, setProductCatData] = useState([]);
  const [productTitleData, setProductTitleData] = useState([]);
  const [productContentData, setProductContentData] = useState([]);
  const [elementHead, setelementHead] = useState([]);
  const [title, setTitle] = useState([]);
  const [tab, setTab] = useState([]);
  const [image, setImage] = useState([]);
  const [titledesc, setTitledesc] = useState([]);
  const [heading1, setHeading1] = useState([]);
  const [heading2, setHeading2] = useState([]);
  const [heading3, setHeading3] = useState([]);
  const [desc1, setDesc1] = useState([]);
  const [desc2, setDesc2] = useState([]);
  const [desc3, setDesc3] = useState([]);
  const [description1, setdescription1] = useState([]);
  const [description2, setdescription2] = useState([]);
  const [description3, setdescription3] = useState([]);
  const [customerBullet, setcustomerBullet] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const pop_title=[];
  const pop_description=[];
  const pop_image=[];
  const pop_Heading1=[];
  const pop_Heading2=[];
  const pop_Heading3=[];
  const pop_Desc1=[];
  const pop_Desc2=[];
  const pop_Desc3=[];
  const [fullscreen, setFullscreen] = useState(true);
    const [display, setDisplay] = useState(false);
    const clickShow = (breakpoint) => {
        setFullscreen(breakpoint);
        setDisplay(true);
      }
  useEffect(() => { 

    var config = { "Access-Control-Allow-Origin": "*" }
    productCatalogueApi(config, (response) => {
    //success
    const productTitle = response.data.map(item => item.contentTitle);
    const productContent = response.data.map(item => item.contentDescription);
    const productElement = response.data.map(item => item.contentElements);
    setProductTitleData(productTitle);
    //console.log("product Heading",productTitleData);
    setProductContentData(productContent);
    //console.log("product content",productContentData);
    var prodData=productElement[0];
    setProductCatData(prodData);
    for(let i=0;i<prodData.length;i++)
                {
                    pop_title.push(prodData[i].ElementTitle);
                    pop_description.push(prodData[i].ElementDescription);
                    pop_image.push(prodData[i].ElementLogo);
                    pop_Heading1.push(prodData[i].ElementPart1Heading1);
                    pop_Heading2.push(prodData[i].ElementPart2Heading2);
                    pop_Heading3.push(prodData[i].ElementPart3Heading3);
                    pop_Desc1.push(prodData[i].ElementPart1Description1);
                    pop_Desc2.push(prodData[i].ElementPart2Description2);
                    pop_Desc3.push(prodData[i].ElementPart3Description3);
                }
                setTitle(pop_title);
                setTitledesc(pop_description);
                setImage(pop_image);
                setHeading1(pop_Heading1);
                setHeading2(pop_Heading2);
                setHeading3(pop_Heading3);
                setDesc1(pop_Desc1);
                setDesc2(pop_Desc2);
                setDesc3(pop_Desc3);
    const Elementcontent = productCatData.map(item => item.ElementTitle);
    //console.log("productElement", Elementcontent);
    
    setelementHead(Elementcontent);
    },(err) => {
        //error
        console.log(err);
    });
      
  }, []);
  const popData = (index) =>{
    setTab(index);
    handleShow(true);
    //console.log("data in onClick",desc1[index]);
    setdescription1(desc1[index]);
    setdescription2(desc2[index]);
    setdescription3(desc3[index]);
  }
 // console.log("product catlogue",productCatData);
 // console.log("product title",title);
 // console.log("description", desc1);
 // console.log("tabIndex", tab);
  
  return (
    <div className="bics-product-wrapper py-md-5 py-3 py-lg-5 py-xl-6 py-sm-3">
      <Container>
        <div className="bics-header-wrapper">
          <div className="bics-inner-wrapper pb-md-4 pb-lg-4 pb-sm-2 pb-2 pb-xl-4"         
          >
       
            <h1 className="bics-header">{productTitleData}</h1>
       
           
            <p className="bics-content">
              {productContentData}
            </p>
          
          </div>
        </div>
        <Row className="d-flex justify-content-center">
          <Col>
          <AnimateSharedLayout type="crossfade">
               <AnimatePresence exitBeforeEnter>
               <motion.div className='bics-product-animate'
                        initial={"offscreen"} 
                        whileInView={"onscreen"}
                        viewport={{once:false, amount:0.1}}
                        transition={{staggerChildren:0.5,  delayChildren: .1, when: 'beforeChildren',}}
                >
            <Carousel 
            swipeable={false}
            draggable={false}
            // showDots={true}
            // removeArrowOnDeviceType={["desktop"]}
            containerClass="carousel-container"
            itemClass="carousel-item-padding-16-px"
            responsive={responsive} >
            {productCatData.map((item, index) => (
              <div className="bics-item-slide" key={index}>
                  <motion.div className='bics-item-img-animation' variants={productAnimate}>
                <Card>
                  <Card.Img variant="top" src={process.env.REACT_APP_BASE_URL + item.ElementLogo} />
                  <Card.Body className='pb-0'>                 
                    <Card.Text>
                  <span className='product_cat_head'>{item.ElementTitle}</span>
                  <Button className='mt-2 mb-0 prod_read prod_button_more' onClick={() => popData(index)}>More Info</Button>
                    </Card.Text>
                  </Card.Body>
                </Card>
                </motion.div>
                
              </div>
              
            ))}
                
            </Carousel>
            </motion.div>
            </AnimatePresence>
            </AnimateSharedLayout>
          </Col>
          <Modal className='modal_portfolio_width'
        show={show}
        onHide={() => setShow(false)}
        size='xl'
       dialogClassName='product-popup-box'
        //dialogClassName="modal-100w"
        aria-labelledby="contained-modal-title-vcenter"
      >
        {/* <Modal.Header closeButton className='bor_none'>
          
        </Modal.Header> */}
        <Modal.Body className='prod_pop_body'>
          <Row className='px-0 px-xl-4'>
          <Col xs={12} xl={12}> <div className='text-right'>
            <Button variant="secondary" onClick={handleClose} className="close_prod_btn desk_show">
            x
          </Button>
          </div>
          </Col>
            <Col xs={12} xl={6} className='align-self-center prod_left_cont p-4 my-4'>
            <div>
            {/* <Button variant="secondary" onClick={handleClose} className="close_prod_btn mob_show">x</Button> */}

              <div className='text-center'>
                <img src={process.env.REACT_APP_BASE_URL + image[tab]} className='prod_border-rounded' />
              </div>
            <h4 className='text-center py-4'>{title[tab]}</h4>
            <p className='text-center'>{titledesc[tab]}</p>
          </div>
            </Col>
            
            <Col xs={12} xl={6} className='my-4'>
              
          <div className='px-0 px-xl-4'>
              <h6>{heading1[tab]}</h6>
              
              {description1.map((list, index) => (
                <p className="mb-2 px-3" key={index}>{list.ElementDescription}</p>
              ))}
              
              <h6>{heading2[tab]}</h6>
              
              {description2.map((list, index) => (
                <p className="mb-2 px-3" key={index}>{list.ElementDescription}</p>
              ))}
              
              <h6>{heading3[tab]}</h6>
              
              {description3.map((list, index) => (
                <p className="mb-2 px-3" key={index}>{list.ElementDescription}</p>
              ))}
              
              </div>
               </Col>
          </Row>
        </Modal.Body>
      </Modal>
        </Row>
      </Container>
      
    </div>
  );
}

export default ProductCatalogue;
