import axios from "axios";

// export const homeSliderApi = sliderbox => {
//     const URL = `https://bgnewsiteapi.bicsglobal.com/getPage1Block1`;
//     return axios(URL, {
//         method: 'GET',
//         headers: {
//           'content-type': 'application/json', // whatever you want
//         },
//         data: sliderbox,
//       })
//         .then(response => response.data)
//         .catch(error => {
//           throw error;
//         });
     
// };

export function homeSliderApi(config, callback, errorcallback){
  const URL = process.env.REACT_APP_BASE_URL+'getPage1Block1';
    axios.get(URL, config)
    .then(res => {
      //do something
     // console.log("api working");
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}

export function earnValueApi(config, callback, errorcallback){
  const URL = process.env.REACT_APP_BASE_URL+'getPage1Block2';
  axios.get(URL, config)
  .then(res => {
    //do something
   // console.log("api working");
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function businessCatalogueApi(config, callback, errorcallback){
  const URL = process.env.REACT_APP_BASE_URL+'getPage1Block3';
  axios.get(URL, config)
  .then(res => {
    //do something
   // console.log("businessCatalogueApi working");
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function serviceCatalogueApi(config, callback, errorcallback){
  const URL = process.env.REACT_APP_BASE_URL+'getPage1Block4';
  axios.get(URL, config)
  .then(res => {
    //do something
    //console.log("serviceCatalogueApi working");
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}


export function productCatalogueApi(config, callback, errorcallback){
  const URL = process.env.REACT_APP_BASE_URL+'getPage1Block5';
  axios.get(URL, config)
  .then(res => {
    //do something
    //console.log("serviceCatalogueApi working");
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function industryServiceApi(config, callback, errorcallback){
  const URL = process.env.REACT_APP_BASE_URL+'getPage1Block6';
  axios.get(URL, config)
  .then(res => {
    //do something
   // console.log("industryServiceApi working");
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function voiceOfCustomerApi(config, callback, errorcallback){
  const URL = process.env.REACT_APP_BASE_URL+'getPage1Block7';
  axios.get(URL, config)
  .then(res => {
    //do something
   // console.log("voiceOfCustomerApi working");
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}

export function stayInTouchApi(config, callback, errorcallback){
  const URL = process.env.REACT_APP_BASE_URL+'getPage1Block8';
  axios.get(URL, config)
  .then(res => {
    //do something
   // console.log("stayInTouchApi working");
    if(callback != null){
       callback(res);
    }
  })
  .catch(err => {
    // catch error
    if(errorcallback != null){
       errorcallback(err);
    }
  })
}
