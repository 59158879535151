import { React, useEffect, useState } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';
import $ from "jquery";
import { industryServiceApi } from '../API/homeAPI';
import { Animated } from 'react-animated-css';

const Industry = () => {

    function textLoader () {
        $(document).ready(function(){

        $('#text').html($('#industrySlider .active > .carousel-caption').html());
       
        $('#industrySlider').on('slid.bs.carousel', function (event) {
          $('#text').html($('#industrySlider .active > .carousel-caption').html());
                 
        });
    });
    }

    const [industryCatData, setIndustryCatData] = useState([]);
    const [industryTitleData, setIndustryTitleData] = useState([]);
    const [industryContentData, setIndutryContentData] = useState([]);

    useEffect(() => { 

      var config = { "Access-Control-Allow-Origin": "*" }
    industryServiceApi(config, (response) => {
    //success
    const industryTitle = response.data.map(item => item.contentTitle);
    const industryContent = response.data.map(item => item.contentDescription);
    const industryElement = response.data.map(item => item.contentElements);
    setIndustryTitleData(industryTitle);
    setIndutryContentData(industryContent);
    setIndustryCatData(industryElement[0]);

    //console.log("industryElement", response.data[0].contentElements);
    },(err) => {
        //error
        console.log(err);
    });

      const interval = setInterval(() => {
        textLoader(); 
      }, 500);    
      return () => clearInterval(interval);

      
        // setTimeout(() => {
        //   textLoader();          
        // }, 100);
      }, []);
          
    return (
      <div className="bics-service-wrapper py-md-5 py-3 py-lg-5 py-xl-6 py-sm-3">
        <Container>
          <div className="bics-header-wrapper">
            <div className="bics-inner-wrapper pb-md-4 pb-lg-4 pb-sm-2 pb-2 pb-xl-4">
              <h1 className="bics-header">{industryTitleData}</h1>
              <p className="bics-content">
                {industryContentData}
              </p>
            </div>
          </div>
          <Row className="d-flex justify-content-center text-md-left">
            <Col md={6}>
            <Animated
            animationIn="slideInDown"
            animationOut="fadeOutUp"
            animationInDuration={1000}
            animationInDelay={100}
            animationOutDelay={100}
            isVisible={true}
          >
              <div id="text" className='bics-carousel-caption'></div>
              </Animated>
            </Col>
            <Col md={6}>
              <Carousel
                id="industrySlider"
                autoPlay={true}
                interval={3000}
                controls={true}
                pause={false}
              >
                 {industryCatData.map((item, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={process.env.REACT_APP_BASE_URL + item.ElementLogo}
                    alt="First slide"
                  />
                  
                  <Carousel.Caption>
                    <h3>
                      {item.ElementTitle}
                    </h3>
                    <p>
                    {item.ElementDescription}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                ))}
                
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    );

}

export default Industry;