import { React, useState } from 'react';
import HomeBanner from '../../components/homeBanner';
import EarnValue from '../../components/EarnValues';
import BusinessCatalogue from '../../components/BusinessCatalogue';
import ServiceCatalogue from '../../components/ServiceCatalogue';
import ProductCatalogue from '../../components/ProductCatalogue';
import Industry from '../../components/Industry';

import VoiceOfCustomer from '../../components/VoiceOfCustomer';
import ValueClient from '../../components/ValueClient';
import Transitions from '../../components/Transition';

import HomeVideo from './../../components/homeVideo';


const Home = () => {

//   const [show, setShow] = useState(true);
//   const handleClose = () => {
//     setShow(false);
//     // $('.fadeInLeft').removeClass('animated');
//     // $(this).addClass('animated');
//   }

//   $(document).ready(function() {
//     // setTimeout(setShow(true),1000);
//     const is_already_show=sessionStorage.getItem('alreadyShow');
//     if(is_already_show !== 'already shown'){
//         sessionStorage.setItem('alreadyShow','already shown');
//         setShow(true);
//     }
//     else{
//       setShow(false);
//       console.log("already Shown");
//     }
    
// });

  // useEffect(() => {
  //   $('.fadeInLeft').removeClass('animated');
  //   const timer = setTimeout(() => {      
  //     $('.fadeInLeft').addClass('animate__fadeInLeft');
  //   }, 100);
  //   return () => clearTimeout(timer);
  // }, []);
  //const handleShow = () => setShow(true);

    return (

        <div className='bics-home-container'>
          {/* <AppHeader /> */}
          {/* <Transitions> */}
          <HomeVideo />
          <ValueClient />
          {/* <EarnValue /> */}
          <BusinessCatalogue />
          <ServiceCatalogue />
          <ProductCatalogue />
          <Industry />
          <VoiceOfCustomer />
          {/* <StayTouch /> */}
          {/* </Transitions> */}
		  {/* <Footer /> */}

              
        </div>

    );
    
}

export default Home;
