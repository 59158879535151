import React from "react";
// import Slider from "infinite-react-carousel";
// import Img from "../assets/images/hero_banner_new.png";
import Carousel from 'react-bootstrap/Carousel';
import AnimatedText from 'react-animated-text-content';
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { homeSliderApi } from './../API/homeAPI';
// import Loader from "./Loader";
import Video_1 from "../assets/images/Intelligence_02.mp4";
import Video_2 from "../assets/images/Intelligence.mp4";
import Video_3 from "../assets/images/Automation.mp4";
import Video_4 from "../assets/images/Enterprise.mp4";

import Img1 from "../assets/images/04_Intelligence_New.png";
import Img2 from "../assets/images/01_Digital_Solutions_New.png";
import Img3 from "../assets/images/02_Enterprise_Solutions_New.png";
import Img4 from "../assets/images/03_Automation_Experts_New.png";

const HomeVideo = () => {
  const [bannerData, setBannerData] = useState([]);
  const [imageData, setImageData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const videoData = [
    {
      video: Video_1,
      image: Img1 // Replace with the actual image URL or path
    },
    {
      video: Video_2,
      image: Img4 // Replace with the actual image URL or path
    },
    {
      video: Video_3,
      image: Img2 // Replace with the actual image URL or path
    },
    {
      video: Video_4,
      image: Img3 // Replace with the actual image URL or path
    }
  ];
//   const videoData = [Video_1, Video_2, Video_3, Video_4];
//   const ImageData = [Img1];
  useEffect(() => { 
    // setLoading(true);
    var config = { "Access-Control-Allow-Origin": "*" }
    homeSliderApi(config, (response) => {
    //success
    // const bannerPic=response.data[0].contentElements;

    // setImageData(response.data[0].contentBackground);
   // console.log("image bacjground", imageData);
    setBannerData(response.data[0].contentElements);
   // console.log("response banner", response.data[0].contentElements);
    
  //   setTimeout(() => {
  //     setLoading(false)
  // }, 3000);
    },(err) => {
        //error
        console.log(err);
    });
      
  }, []);

//   if (loading) {
//     return <Loader />;
// }
    return (
        <section className="section">
       
          <Container fluid className="p-0">
          <Carousel autoPlay={true} interval={6500} controls={false} pause={false} className="bics-home-slider" fade>
          {videoData.map((item, index) => (
  <Carousel.Item key={index}>
    {/* <img
      className="d-block w-100"
      src={process.env.REACT_APP_BASE_URL + item.ElementBackground}
      alt="First slide"
    /> */}
    <div class="awesome-highlight">
             <video
            className="slider-video"
            src={item.video}
            loop
            autoPlay
            muted
            loading="lazy"
          ></video>
          </div>
    <Carousel.Caption>
      
      <div>
     <img
     className="d-block w-100"
     src={item.image}
     alt="First slide"
   /></div>




{/* <AnimatedText
  type="words" // animate words or chars
  animation={{
    x: '200px',
    y: '-20px',
    scale: 1.1,
    ease: 'ease-in-out',
  }}
  animationType="blocks"
  interval={0.06}
  duration={0.8}
  tag="p"
  className="animated-paragraph"
  includeWhiteSpaces
  threshold={0.1}
  rootMargin="20%"
>
{item.ElementDescription}
</AnimatedText> */}

    </Carousel.Caption>
  </Carousel.Item>  
))}
      </Carousel>
      </Container>
        </section>
    )
}

export default HomeVideo;