import { React, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { serviceCatalogueApi } from '../API/homeAPI';
import Textify from "textify.js";
import Fade from 'react-reveal/Fade';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/all';
import {motion} from "framer-motion";


const ServiceCatalogue = () => {
  const [serviceCatData, setServiceCatData] = useState([]);
  const [serviceTitleData, setServiceTitleData] = useState([]);
  const [serviceContentData, setServiceContentData] = useState([]);
  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  
  const imageAnimate={
    offscreen:{x:-100, opacity:0},
    onscreen:{
      x:0,
      opacity:1,
      //rotate:[0,360,-360,360,0],
      transition:{type:"spring",
      bounce:0.4,
      duration:2,
      delayChildren: 0.1,
      }
    }
  }
  const paraAnimate={
    offscreen:{y:100, opacity:0},
    onscreen:{
      y:0,
      opacity:1,
      transition:{type:"spring",
      bounce:0.4,
      duration:3,
      ease: "easeInOut",
      }
    }
  }

  const serviceBoxAnimate={
    offscreen:{y:50, opacity:0},
    onscreen:{
      y:0,
      opacity:1,
      transition:{
      type:"spring",
      ease: "easeInOut",
      bounce:0.4,
      duration:3,
      
      }
    }
  }

  useEffect(() => { 

   
 
    var config = { "Access-Control-Allow-Origin": "*" }
    serviceCatalogueApi(config, (response) => {
    //success
    const serviceTitle = response.data.map(item => item.contentTitle);
    const serviceContent = response.data.map(item => item.contentDescription);
    const serviceElement = response.data.map(item => item.contentElements);
    setServiceTitleData(serviceTitle);
    setServiceContentData(serviceContent);
    setServiceCatData(serviceElement[0]);

    //console.log("serviceElement", response.data[0].contentElements);
    },(err) => {
        //error
        console.log(err);
    });

    setTimeout(() => {
      const animations = new Textify({
        selector:".parabox",
        once: false,
        top:true,
        // top: false,
        // rotation: 20,
        rotation: -10,
        easing: "elasticInOut",
        scale: 0,
        // scale: 2,
        fade: true,
        //ease: "circInOut",
        // fadeEasing: "back",
       duration: 1000,
        delay: 100
        });
    }, 100);
      
  }, []);

    return (
      <div className="bics-service-wrapper py-md-5 py-3 py-lg-5 py-xl-6 py-sm-3">
        <Container>
          <div className="bics-header-wrapper">
            <div className="bics-inner-wrapper pb-md-4 pb-lg-4 pb-sm-2 pb-2 pb-xl-4">     
              <h1 className="bics-header">{serviceTitleData}</h1>
              <p className="bics-content">
              {serviceContentData}
              </p>
            </div>
          </div>
          <Row className="d-flex justify-content-center">
          {serviceCatData.map((item, index) => (
            <Col lg={6} key={index}>
               <motion.div className='bics-card-inner'
                 initial={"offscreen"} 
                 whileInView={"onscreen"}
                 viewport={{once:false, amount:1}}
                 transition={{staggerChildren:1}}
                  >
              <Card className='my-3'>
             
                <motion.div variants={imageAnimate}>
                <Card.Img variant="top" src={process.env.REACT_APP_BASE_URL + item.ElementLogo} />
                </motion.div>
                <Card.Body className='parabox'>
                  <motion.div variants={paraAnimate}>
                  <Card.Title>{item.ElementTitle}</Card.Title>
                  <Card.Text>
                   {item.ElementDescription}
                  </Card.Text>
                  </motion.div>
                </Card.Body>                
              </Card>
              </motion.div>
            </Col>
             ))} 
          </Row>
        </Container>
      </div>
    );

}

export default ServiceCatalogue;