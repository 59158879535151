import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import arrowIcon from "../../assets/images/icons/footer_bulletin_arrow_icn.png";

const ServiceFooter = () => {
  return (
    <div>
      <Row>
        <Col xs={12}>
          <h5 className="text-left footertitle footer_text_color">Services</h5>
          <Row className="footer_mob_pad">
            <Col xs={12} lg={6}>
              <div className="d-flex">
                <div className="footer_icon">
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color pb-0">
                    <Link to=""><strong>Enterprise Services</strong></Link>
                  </div>
                </div>
              </div>
              <div className="d-flex"> 
              <div className="footer_icon">
                 &nbsp;
                </div>              
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/salesforce">Salesforce</Link>
                  </div>
                </div>
              </div>
              
              <div className="d-flex">  
              <div className="footer_icon">
                  &nbsp;
                </div>             
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/marketingcloud">Marketing Cloud</Link>
                  </div>
                </div>
              </div>

 <div className="d-flex">  
              <div className="footer_icon">
                  &nbsp;
                </div>             
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color">
                    <Link to="/sap">SAP</Link>
                  </div>
                </div>
              </div>

            </Col>
            <Col xs={12} lg={6}>
              <div className="d-flex">
                <div className="footer_icon">
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color pb-0">
                    <Link to=""><strong>Futuristic Solutions</strong></Link>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="footer_icon">
                &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/intelligent">Artificial Intelligence</Link>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="footer_icon">
                  &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/analytics">Analytics</Link>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="footer_icon">
                  &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/automations">Automation</Link>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="footer_icon">
                  &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/devops">DevOps</Link>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="footer_icon">
                  &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/cloud">Cloud</Link>
                  </div>
                </div>
              </div>

            </Col>
          </Row>

          <Row className="footer_mob_pad">
            <Col xs={12} lg={12}>
              <div className="d-flex">
                <div className="footer_icon">
                  <img src={arrowIcon} alt="Arrow Icon" />
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color pb-0">
                    <Link to=""><strong>Digital Intelligence Experience Platform</strong></Link>
                  </div>
                </div>
              </div>
              <Row>
              <Col xs={12} lg={6}>
              <div className="d-flex">
                <div className="footer_icon">
                &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/digitalcms">Digital CMS</Link>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="footer_icon">
                &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/ecommerce">Digital Commerce</Link>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="footer_icon">
                &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/mobility">Digital Mobility</Link>
                  </div>
                </div>
              </div>
</Col>
<Col xs={12} lg={6}>
              <div className="d-flex">
                <div className="footer_icon">
                &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/technologies">Technology</Link>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="footer_icon">
                &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color mb-0">
                    <Link to="/uiux">UI/UX</Link>
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="footer_icon">
                &nbsp;
                </div>
                <div className="text-left">
                  <div className="servicetextFooter footer_text_color">
                    <Link to="/lms">LMS</Link>
                  </div>
                </div>
              </div>
              </Col>
</Row>
            </Col>
          </Row>

         
        </Col>
      </Row>
    </div>
  );
};

export default ServiceFooter;
