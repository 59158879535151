import React from 'react'
import {Col, Row} from 'react-bootstrap'
import linkedinIcon from '../../assets/images/icons/footer_linkedin_icn.png'
import facebookIcon from '../../assets/images/icons/footer_facebook_icn.png'
import twitterIcon from '../../assets/images/icons/footer_twitter_icn.png'
import instaIcon from '../../assets/images/icons/footer_instagram_icn.png'
import upworkIcon from '../../assets/images/icons/con_upwork_icn.svg'
import clutchIcon from '../../assets/images/icons/con_clutch_icn.svg'

const SocialFooter = () => {
  return (
    <div>
        {/* ----------------Social Media Links--------------------- */}
        <Row>
        <Col xs={12}>
            <h5 className='text-left footertitle footer_text_color'>FIND US</h5>
        </Col>
      </Row> 
      <Row className="footer_mob_pad">
        <Col xs={12}>
            <div className='d-flex social_padding'>
                <div className='footer_icon'>
                  <img src={clutchIcon} alt="location Icon" width={18}/></div>
                <div className="text-left">
                  <a href="https://clutch.co/profile/bics-global" target="_blank" className='footer_findus_text'>www.clutch.co</a>
                </div>
            </div>
            <div className='d-flex social_padding'>
                <div className='footer_icon'>
                  <img src={upworkIcon} alt="location Icon" width={18}/></div>
                <div className="text-left">
                  <a href="https://www.upwork.com/o/companies/~016589f7353b51eca8/" target="_blank" className='footer_findus_text'>www.upwork.com</a>
                </div>
            </div>
        </Col>
      </Row>  
      <Row>
        <Col xs={12}>
            <h5 className='text-left footertitle footer_text_color'>FOLLOW US</h5>
        </Col>
      </Row> 
      <Row className="footer_mob_pad">
      <Col xs={12} md={12}>
            <div className='d-flex social_padding'>
                <div className='footer_icon'>
                  <img src={linkedinIcon} alt="location Icon" width={16}/></div>
                <div className="text-left">
                  <a href="https://in.linkedin.com/company/bicsglobal" target="_blank" className='footer_findus_text'>www.linkedin.com</a>
                </div>
            </div>
            
            <div className='d-flex social_padding'>
                <div className='footer_icon'>
                  <img src={twitterIcon} alt="location Icon" width={16}/></div>
                <div className="text-left">
                  <a href="https://twitter.com/bicsglobal" target="_blank" className='footer_findus_text'>www.twitter.com</a>
                </div>
            </div>
        </Col>
        <Col xs={12} md={12}>
        <div className='d-flex social_padding'>
                <div className='footer_icon'>
                  <img src={facebookIcon} alt="location Icon" width={16}/></div>
                <div className="text-left">
                  <a href="https://www.facebook.com/bicsglobal" target="_blank" className='footer_findus_text'>www.facebook.com</a>
                </div>
            </div>
            <div className='d-flex social_padding'>
                <div className='footer_icon'>
                  <img src={instaIcon} alt="location Icon" width={16}/></div>
                <div className="text-left">
                  <a href="https://www.instagram.com/bicsglobal/" target="_blank" className='footer_findus_text'>www.instagram.com</a>
                </div>
            </div>
        </Col>
        
      </Row>
    </div>
  )
}

export default SocialFooter
