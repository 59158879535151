import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { voiceOfCustomerApi } from "../API/homeAPI";
import VideoOfCustomer from "./videoOfCustomer";
import $ from "jquery";
import Vocimg from "../assets/images/voc_profile_img.png";
import Vocimgg from "../assets/images/voc_futuristic_frame_img.png";

import config from "../config.json";
// import Visual from './Visual';
// import { Media, Player, controls, utils } from 'react-media-player'
var baseurl = `${config.baseurl}`;
const voiceName = [];
var voiceElement;
const VoiceOfCustomer = () => {
  const [voiceTitleData, setVoiceTitleData] = useState([]);
  const [voiceContentData, setVoiceContentData] = useState([]);
  const [voiceElementData, setVoiceElementData] = useState([]);
  const [mediaType, setMediaType] = useState("");
  const [voiceIndex, setvoiceIndex] = useState(0);
  const [voiceFilterData, setVoiceFilterData] = useState([]);
  const [voiceCust, setVoiceCust] = useState([]);
  useEffect(() => {
    var config = { "Access-Control-Allow-Origin": "*" };
    voiceOfCustomerApi(
      config,
      (response) => {
        //success
        const voiceTitle = response.data.map((item) => item.contentTitle);
        const voiceContent = response.data.map(
          (item) => item.contentDescription
        );
        voiceElement = response.data.map((item) => item.contentElements);
        const voiceFilter = voiceElement[0];
        setVoiceTitleData(voiceTitle);
        setVoiceContentData(voiceContent);
        setVoiceElementData(voiceFilter);
        setMediaType(voiceElement[0][0].ElementTitle);
        setVoiceFilterData(voiceElement[0][0]);

        // console.log("voiceOfCustomerApi", response.data);
        // console.log("voiceElement", voiceFilter);
        for (let i = 0; i < voiceElement[0].length; i++) {
          voiceName.push(voiceElement[0][i].ElementName);
        }
        setVoiceCust(voiceName);
      },
      (err) => {
        //error
        console.log(err);
      }
    );
  }, []);
  const audioTrack = baseurl + voiceFilterData.ElementUrl;
  const musicTracks = [
    {
      name: "Bics Global",
      src: audioTrack,
    },
  ];

  const [trackIndex, setTrackIndex] = useState(0);
  const handleClickPrevious = () => {
    setTrackIndex((currentTrack) =>
      currentTrack === 0 ? musicTracks.length - 1 : currentTrack - 1
    );
  };
  const handleClickNext = () => {
    setTrackIndex((currentTrack) =>
      currentTrack < musicTracks.length - 1 ? currentTrack + 1 : 0
    );
  };

  var voiceElementLength = voiceElementData.length - 1;
  var voiceNameLength = voiceElementData.length - 1;
  //console.log("length",voiceNameLength);
  if (voiceIndex === voiceNameLength) {
    $(".bics-media-footer-inner").hide();
  }
  //To get file type(image,audio or video) in button click
  const loadVoiceElementData = () => {
    if (voiceIndex === voiceElementLength) {
      setMediaType(voiceElementData[0].ElementTitle);
      setVoiceFilterData(voiceElementData[0]);
      setvoiceIndex(0);
    } else {
      setMediaType(voiceElementData[voiceIndex + 1].ElementTitle);
      setVoiceFilterData(voiceElementData[voiceIndex + 1]);
      setvoiceIndex(voiceIndex + 1);
    }
  };

  // console.log("voiceFilterData out",voiceFilterData);
  // var voiceValue ="";
  // if( mediaType === "Audio") {
  //   voiceValue = <p>a</p>
  // }
  // else if(mediaType === "Image"){
  //   voiceValue = <p>b</p>
  // }
  // else {
  //   voiceValue =  <p>c</p>
  // }
  const imgpath = `${config.baseurl}`;
  return (
    <div className="bics-voice-wrapper py-md-5 py-3 py-lg-5 py-xl-6 py-sm-3">
      <Container>
        <div className="bics-header-wrapper">
          <div className="bics-inner-wrapper pb-md-4 pb-lg-4 pb-sm-2 pb-2 pb-xl-4">
            <h1 className="bics-header">{voiceTitleData}</h1>
            <p className="bics-content">{voiceContentData}</p>
          </div>
        </div>
        <div>
          {/* {voiceValue} */}
          {/* {mediaType} */}
        </div>
        <div className="bics-voice-box">
          <Row>
            <Col xs={12} lg={6}>
              {(function () {
                if (voiceFilterData.ElementTitle === "Image") {
                  return (
                    <div className="bics-audio-inner bics-img-container">
                      <div className="outer-container">
                        <div className="item">
                          <img
                            alt="client pic"
                            src={
                              process.env.REACT_APP_BASE_URL +
                              voiceFilterData.ElementUrl
                            }
                          />
                        </div>
                        <div className="bics-client-backdrop">
                          <img
                            alt="client pic"
                            src={
                              process.env.REACT_APP_BASE_URL +
                              voiceFilterData.ElementUrl
                            }
                          />
                        </div>
                      </div>
                      {/* <div className='bics-client-box'>
                      <div className='bics-client-name'>{voiceFilterData.ElementTitle}</div>
                      <div className='bics-client-description'>{voiceFilterData.ElementDescription}</div>
                      </div> */}
                    </div>
                  );
                } else if (voiceFilterData.ElementTitle === "Audio") {
                  return (
                    <div className="bics-audio-inner audio-waveform">
                      <div className="outer-container">
                        <div className="item">
                          <img
                            alt="client pic"
                            src={
                              process.env.REACT_APP_BASE_URL +
                              voiceFilterData.ElementImage
                            }
                          />
                          <div className="bics-rotate-img">
                            <img
                              className="bics-spin"
                              alt="client pic"
                              src={Vocimgg}
                            />
                          </div>
                        </div>

                        {/* <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div>
                      <div className="circle"></div> */}
                      </div>
                      <AudioPlayer
                        autoPlay={false}
                        // layout="horizontal"
                        src={musicTracks[trackIndex].src}
                        onPlay={(e) => console.log("onPlay")}
                        showSkipControls={true}
                        showJumpControls={false}
                        // header={`Now playing: ${musicTracks[trackIndex].name}`}
                        // footer="All music from: www.bensound.com"
                        onClickPrevious={handleClickPrevious}
                        onClickNext={handleClickNext}
                        onEnded={handleClickNext}
                        customAdditionalControls={[]}
                        customVolumeControls={[]}
                        // other props here
                      />
                    </div>
                  );
                } else {
                  return <VideoOfCustomer videoData={voiceFilterData} />;
                }
              })()}
            </Col>
            <Col xs={12} lg={6}>
              {(function () {
                if (voiceFilterData.ElementTitle === "Image") {
                  return (
                    <div className="bics-voice-right">
                      <div className="media">
                        <img
                          className="mr-3"
                          src={
                            process.env.REACT_APP_BASE_URL +
                            voiceFilterData.ElementUrl
                          }
                          alt="img"
                        />
                        <div className="media-body">
                          <h5 className="mt-0">
                            {voiceFilterData.ElementName}
                          </h5>
                          {voiceFilterData.ElementSubTitle}
                        </div>
                      </div>
                      <div className="bics-media-subheading">
                        {voiceFilterData.ElementDescription}
                      </div>
                      <div className="bics-media-footer">
                        <div className="bics-media-footer-inner mr-5">
                          <button onClick={() => loadVoiceElementData()}>
                            <span>{voiceCust[voiceIndex + 1]}</span>
                            <div className="arrow"></div>
                            {/* <FontAwesomeIcon icon={faAngleRight} /> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                } else if (voiceFilterData.ElementTitle === "Audio") {
                  return (
                    <div className="bics-voice-right">
                      <div className="media">
                        <img
                          className="mr-3"
                          src={
                            process.env.REACT_APP_BASE_URL +
                            voiceFilterData.ElementImage
                          }
                          alt="img"
                        />
                        <div className="media-body">
                          <h5 className="mt-0">
                            {voiceFilterData.ElementTitle}
                          </h5>
                          {voiceFilterData.ElementDescription}
                        </div>
                      </div>
                      <div className="bics-media-subheading">
                        As an oDesk and Upwork veteran I am always nervous about
                        trying a new contractor. I can't be more happy. They
                        have created a lovely design which was better than
                        expected and they are very easy to deal with. I am
                        rehiring them straight away to continue the work.
                      </div>
                      <div className="bics-media-footer">
                        <div className="bics-media-footer-inner">
                          <button onClick={() => loadVoiceElementData()}>
                            <span>{voiceCust[voiceIndex + 1]}</span>
                            <FontAwesomeIcon icon={faAngleRight} />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="bics-voice-right">
                      <div className="media">
                        <img className="mr-3" src="..." alt="img" />
                        <div className="media-body">
                          <h5 className="mt-0">
                            {voiceFilterData.ElementTitle}
                          </h5>
                        </div>
                      </div>
                      <div className="bics-media-subheading">
                        {voiceFilterData.ElementDescription}
                      </div>
                      <div className="bics-media-footer">
                        <div className="bics-media-footer-inner">
                          <button onClick={() => loadVoiceElementData()}>
                            <span>{voiceCust[voiceIndex + 1]}</span>
                            <FontAwesomeIcon icon={faAngleRight} />
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }
              })()}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default VoiceOfCustomer;
