import React from 'react'
import {Col, Row} from 'react-bootstrap'
import locationIcon from '../../assets/images/icons/footer_location_icn.png'
import phoneIcon from '../../assets/images/icons/footer_contact_icn.png'
import mailIcon from '../../assets/images/icons/footer_mail_icn.png';


const Reachus = () => {
  return (
    <div>
        <Row>
        <Col xs={12}>
            <h5 className='text-left footertitle footer_text_color'>REACH US</h5>
        </Col>
      </Row>
      {/* ------------------Address Block----------------- */}
      <Row className="footer_mob_pad">
        <Col xs={12}>
            <div className='d-flex'>
                <div className='footer_icon'><img src={locationIcon} alt="location Icon"/></div>
                <div
                className="text-left">
                <div className='addresstitle footer_text_color'>Prince Info Park,</div>
                <div className="addressline1 footer_text_color"> A Block, First Floor, No. 81-B, </div>
                <div className="addressline2 footer_text_color">2nd Main Road, Ambattur I.E, Ambattur,<br/>
                 Chennai-600 058. </div>
                </div>
            </div>
        </Col>
      </Row>
      {/* ------------------Phone Block----------------- */}
      <Row className="footer_mob_pad">
        <Col xs={12}>
            <div className='d-flex mt-4'>
                <div className='footer_icon'><img src={phoneIcon} alt="phone Icon"/></div>
                <div
                className="text-left">
                <div className="phone_text footer_text_color"> 91-44-48601825 </div>
                <div className="phone_text footer_text_color">91-44-48601827</div>
                </div>
            </div>
        </Col>
      </Row>
      {/* ------------------Mail Block----------------- */}
      <Row className="footer_mob_pad">
        <Col xs={12}>
            <div className='d-flex my-4'>
                <div className='footer_icon'><img src={mailIcon} alt="Mail Icon"/></div>
                <div
                className="text-left">
                <div className="Mail_text footer_text_color"><a className="Mail_text footer_text_color" href="mailto:info@bicsglobal.com" target="_blank">info@bicsglobal.com</a></div>
                </div>
            </div>
        </Col>
      </Row>
    
    </div>
  )
}

export default Reachus
