import React, { useState } from "react";
import { ReactComponent as ScrollArrowIcon } from "../assets/images/back_2_top_icn.svg";

const ScrollArrow = () => {
    const [isVisible, setIsVisible] = useState(false);
  
    const changeBackground = () => {
      if (window.scrollY >= 80) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    window.addEventListener("scroll", changeBackground);
    return (
      <>
        {isVisible ? (
          <div onClick={scrollToTop} className="scroll_up">
            <ScrollArrowIcon />
          </div>
        ) : null}
      </>
    );
  };
  
  export default ScrollArrow;