import React from 'react'
import {Col, Row} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import arrowIcon from '../../assets/images/icons/footer_bulletin_arrow_icn.png'


const IndustryFooter = () => {
  return (
    <div>
      <Row>
        <Col xs={12}>
            <h5 className='text-left footertitle footer_text_color'>INDUSTRY WE SERVE</h5>
            <Row className="footer_mob_pad">
        <Col xs={12}>
            <div className='d-flex'>
                <div className='footer_icon'><img src={arrowIcon} alt="Arrow Icon"/></div>
                <div className="text-left">
                <div className='industrytextFooter footer_text_color'>
                <Link to={"/industry?step=0"}>MedTech</Link></div>
                </div>
            </div>
        </Col>
      </Row>
      <Row className="footer_mob_pad">
        <Col xs={12}>
            <div className='d-flex'>
                <div className='footer_icon'><img src={arrowIcon} alt="Arrow Icon"/></div>
                <div
                className="text-left">
                <div className='industrytextFooter footer_text_color'>
                <Link to={"/industry?step=1"}>Consumer Healthcare</Link> </div>
                </div>
            </div>
        </Col>
      </Row>
      <Row className="footer_mob_pad">
        <Col xs={12}>
            <div className='d-flex'>
                <div className='footer_icon'><img src={arrowIcon} alt="Arrow Icon"/></div>
                <div
                className="text-left">
                <div className='industrytextFooter footer_text_color'>
                <Link to={"/industry?step=2"}>Animal Healthcare</Link></div>
                </div>
            </div>
        </Col>
      </Row>
      <Row className="footer_mob_pad">
        <Col xs={12}>
            <div className='d-flex'>
                <div className='footer_icon'><img src={arrowIcon} alt="Arrow Icon"/></div>
                <div className="text-left">
                <div className='industrytextFooter footer_text_color'>
                <Link to={"/industry?step=3"}>Consumer Packaged Goods</Link></div>
                </div>
            </div>
        </Col>
      </Row>
      <Row className="footer_mob_pad">
        <Col xs={12}>
            <div className='d-flex'>
                <div className='footer_icon'><img src={arrowIcon} alt="Arrow Icon"/></div>
                <div
                className="text-left">
                <div className='industrytextFooter footer_text_color'>
                <Link to={"/industry?step=4"}>Supply Chain</Link></div>
                </div>
            </div>
        </Col>
      </Row>
        </Col>
      </Row>
    </div>
  )
}

export default IndustryFooter
