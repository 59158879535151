import React from 'react'
import { useNavigate } from "react-router-dom";
import notfoundImage from '../../assets/images/ErrorPage.png'
const Error = () => {
    const navigate = useNavigate();
  return (
    <div className='error_container'>
      <div class="container">
        <div class="left">
            <img src={notfoundImage} alt="" />
        </div>
        <div class="right">
            <h1>404</h1>
            <h4>Looks like you visited a page that does not exist !</h4>
            <div class="btns">
            <button onClick={()=>navigate("/")}>Return Home</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Error
